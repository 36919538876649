export const PATH = {
  PAGE_NOT_FOUND: "*",
  DEFAULT: "/",
  LOGIN: "/login",
  REGISTRATION: "/register",
  DASHBOARD: "/dashboard",
  // Backend
  BACKEND: "/backend",
  // Payment Info
  SCHEDULER: "/scheduler",
  PENDING: "/pendingpayments", // this one is pending
  TRANSACTION: "/transaction",
  // Client Info
  COSTSHEET: "/costsheet",
  CLIENTDETAILS: "/clientdetails",
  BOOKINGFORM: "/bookingform",
  // Inventory Setup
  INVENTORYSETUP: "/inventorysetup",
  TOWERDETAILS: "/towerdetails",
  UNIT: "unitdetails",
  FLOOR: "floordetails",
  INVENTORYVIEW: "/inventorysetup/view/:towerId",
  FACILITIES: "/facilitiesdetails",
  TAX: "/taxdetials",

  // Master Inventory
  FLOORTHEME: "/floortheme",
  UNITTYPE: "/unittype",
  OWNEDBY: "/ownedby",

  // Payment Master
  PAYMENTTYPE: "/paymenttype",
};
