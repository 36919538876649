
import { PATH } from "./pagePath";
export const COOKIES = {
  ACCESS_TOKEN: "accessToken",
  REFERESH_TOKEN: "refreshToken",
  PROJECT_DETAILS: "projectID",
  CARPET_AREA: "CARPETAREA",
  PHASE_DETAILS: "phaseID",
};


export const ROLETYPECODE = {
  SYSTEM_ADMIN: "SA",
};

export const VALIDATION = {
  MAX_LENGTH: 20,
  MAX_COMPANY_NAME_LENGTH: 50,
  MIN_SKILLS: 2,
  MAX_SKILLS: 30,
  PASSWORD_MIN_LENGTH: 8,
  Max_EMAIL_LENGTH: 250,
  MAX_AGENCY_lENGTH: 100,
  MAX_QUALIFICATION_lENGTH: 100,
  MAX_POSITION_lENGTH: 50,
  MAX_NAME_LENGTH: 20,
};

export const API_STATUS = {
  SUCCESS: "Success",
  FAILED: "Failed",
};

export const LABELS = {
  ScheduleDailyLabel: [
    { name: "Once", key: "O" },
    { name: "Multiple", key: "M" },
  ],
  ScheduleMonthLabel: [
    { name: "Once", key: "O" },
    { name: "Twice", key: "T" },
  ],
  weekDataLabel: [
    { DAY_DESC: "S", DAY_CODE: 1 },
    { DAY_DESC: "M", DAY_CODE: 2 },
    { DAY_DESC: "T", DAY_CODE: 3 },
    { DAY_DESC: "W", DAY_CODE: 4 },
    { DAY_DESC: "T", DAY_CODE: 5 },
    { DAY_DESC: "F", DAY_CODE: 6 },
    { DAY_DESC: "S", DAY_CODE: 7 },
  ],
};

export const OPTIONS = {
  ScheduleDailyLabel: [
    { name: "Once", key: "O" },
    { name: "Multiple", key: "E" },
  ],
  ScheduleMonthLabel: [
    { name: "Once", key: "O" },
    { name: "Twice", key: "T" },
  ],
  scheduleList: [
    { SCHEDULE_DESC: "Periodic Daily", PERIOD: "D", FREQUENCY_TYPE: "P", VALUE: "D" },
    { SCHEDULE_DESC: "Periodic Weekly", PERIOD: "W", FREQUENCY_TYPE: "P", VALUE: "W" },
    { SCHEDULE_DESC: "Periodic Monthly", PERIOD: "M", FREQUENCY_TYPE: "P", VALUE: "M" },
    { SCHEDULE_DESC: "Run Hour Based", PERIOD: "", FREQUENCY_TYPE: "R", VALUE: "R" },
    { SCHEDULE_DESC: "Run to Fail", PERIOD: "", FREQUENCY_TYPE: "F", VALUE: "F" },
  ],
  monthList: [
    { MONTH_DESC: "Fixed Day", MONTH_OPTION: "1" },
    { MONTH_DESC: "Fixed Week Day", MONTH_OPTION: "2" },
  ],
  weekNumList: [
    { MONTHLY_2_WEEK_NUM: 1, VIEW: "1st" },
    { MONTHLY_2_WEEK_NUM: 2, VIEW: "2nd" },
    { MONTHLY_2_WEEK_NUM: 3, VIEW: "3rd" },
    { MONTHLY_2_WEEK_NUM: 4, VIEW: "4th" },
    { MONTHLY_2_WEEK_NUM: 5, VIEW: "5th" },
  ],
};



export const formatDate = (isoDateString: Date) => {
  const date = new Date(isoDateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};


export const menuList = [
  {
    MODULE_CODE: "paymentInfo",
    MODULE_DESCRIPTION: "Payment Info",
    ICON: "pi pi-indian-rupee",
    DETAIL: [
      {
        FUNCTION_DESC: "Proposed Payment",
        URL: PATH.SCHEDULER,
        ADD_RIGHTS: "True",
      },
      {
        FUNCTION_DESC: "Payment Recevied",
        URL: PATH.TRANSACTION,
        ADD_RIGHTS: "True",
      },
      {
        FUNCTION_DESC: "Payments Details",
        URL: PATH.PENDING,
        ADD_RIGHTS: "True",
      },
    ],
  },
  {
    MODULE_CODE: "clientInfo",
    MODULE_DESCRIPTION: "ClientInfo",
    ICON: "pi pi-users",
    DETAIL: [
      {
        FUNCTION_DESC: "Booking Form",
        URL: PATH.CLIENTDETAILS,
        ADD_RIGHTS: "True",
      },
      {
        FUNCTION_DESC: "Cost Sheet",
        URL: PATH.COSTSHEET,
        ADD_RIGHTS: "True",
      },
    ],
  },
  {
    MODULE_CODE: "towerInfo",
    MODULE_DESCRIPTION: "Building Config",
    ICON: "pi pi-building",
    DETAIL: [
      {
        FUNCTION_DESC: "Unit Details",
        URL: "/unitdetails",
        ADD_RIGHTS: "True",
      },
      {
        FUNCTION_DESC: "Floor Details",
        URL: "/floordetails",
        ADD_RIGHTS: "True",
      },

    ],
  },

  {
    MODULE_CODE: "inventoryDetails",
    MODULE_DESCRIPTION: "Inventory Setup",
    ICON: "pi pi-building-columns",
    DETAIL: [
      {
        FUNCTION_DESC: "Inventory",
        URL: PATH?.INVENTORYSETUP,
        ADD_RIGHTS: "True",
      },
      {
        FUNCTION_DESC: "Facilities",
        URL: PATH?.FACILITIES,
        ADD_RIGHTS: "True",
      },
      {
        FUNCTION_DESC: "Taxs",
        URL: PATH?.TAX,
        ADD_RIGHTS: "True",
      },
    ],
  },
  {
    MODULE_CODE: "masterSetup",
    MODULE_DESCRIPTION: "Inventory Master",
    ICON: "pi pi-cog",
    DETAIL: [
      {
        FUNCTION_DESC: "Floor Theme",
        URL: PATH?.FLOORTHEME,
        ADD_RIGHTS: "True",
      },
      {
        FUNCTION_DESC: "Unit Type",
        URL: PATH?.UNITTYPE,
        ADD_RIGHTS: "True",
      },
      {
        FUNCTION_DESC: "Owned By",
        URL: PATH?.OWNEDBY,
        ADD_RIGHTS: "True",
      },
    ],
  },
  {
    MODULE_CODE: "masterPayment",
    MODULE_DESCRIPTION: "Payment Master",
    ICON: "pi pi-cog",
    DETAIL: [
      {
        FUNCTION_DESC: "Payment Type",
        URL: PATH?.PAYMENTTYPE,
        ADD_RIGHTS: "True",
      },
    ],
  },
]

export const hideProjectPath = ["/inventorysetup", "/floortheme", "/unittype", "/ownedby", "/paymenttype"];




